import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 14,
    '& > div:first-child': {
      paddingRight: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > div:last-child': {
      minWidth: 90,
    },
  },
}));

const EventFilter = (props) => {
  const classes = useStyles();
  const { colorPalette, ptsEventID, events, className } = props;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventList, setEventList] = useState([]);
  const EventColors = colorPalette.Events;

  useEffect(() => {
    const eventList = events.map((ev) => processEvent(ev));
    setEventList(eventList);
    if (ptsEventID) {
      const selectedEvent = eventList.find((ev) => ev.ptsEventID === ptsEventID);
      setSelectedEvent(selectedEvent || null);
    }
    // eslint-disable-next-line
  }, [events, ptsEventID]);

  const processEvent = (event) => {
    const { ptsEventID, EventID, Status, CallType, FullLocationAddress } = event;
    return { ptsEventID, EventID, Status, CallType, FullLocationAddress };
  };

  const handleFilterEventChange = (event, value) => {
    if (value !== null) {
      let selectedEventInfo = eventList.filter((event) => event.EventID === value.EventID);
      setSelectedEvent(selectedEventInfo[0]);
      props.onChange(event, selectedEventInfo[0].ptsEventID);
    } else {
      setSelectedEvent(null);
      props.onChange(event, null);
    }
  };

  const renderOption = (option) => {
    return (
      <div className={classes.option}>
        <div>
          <b>
            {option.CallType} - {option.EventID}
          </b>{' '}
          - {option.FullLocationAddress}
        </div>
        <div className="badge" style={{ background: EventColors[option.Status] }}>
          {option.Status}
        </div>
      </div>
    );
  };

  const getOptionLabel = (option) => {
    let label = option.EventID ? option.EventID : '';
    if (option.FullLocationAddress) {
      label += ` - ${option.FullLocationAddress}`;
    }
    return label;
  };

  const getOptionSelected = (option, value) => option.ptsEventID === value.ptsEventID;

  return (
    <Autocomplete
      className={className}
      label="event-autocomplete"
      value={selectedEvent}
      onChange={handleFilterEventChange}
      options={eventList}
      size="small"
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      renderInput={(params) => <TextField {...params} fullWidth label="Event" variant="outlined" />}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    colorPalette: state.config.colorPalette,
    events: state.events,
  };
};

export default connect(mapStateToProps)(EventFilter);
