import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchBulletins } from 'reducers/BulletinReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import { addBulletin, editBulletin } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { showReport } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { notify } from 'reducers/NotifierReducer';
import { removeAlert } from 'reducers/BulletinReducer';
import TextField2 from 'components/TextField2';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
import Checkbox2 from 'components/Checkbox2';

const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minWidth: 200,
    marginBottom: theme.spacing(1),
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const defaultColumns = [
  'AlertType',
  'AlertTypeDescription',
  'AgencyID',
  {
    field: 'Beginning',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Ending',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'Person',
  'Address',
  'Notification',
  'Priority',
  'AlertID',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Deleted',
  'DeletedBy',
];

const noDeletedColumns = [
  'AlertType',
  'AlertTypeDescription',
  'AgencyID',
  {
    field: 'Beginning',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  {
    field: 'Ending',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'Person',
  'Address',
  'Notification',
  'Priority',
  'AlertID',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

function BulletinSearchResults(props) {
  const classes = useStyles();
  const permissions = getFullPermissions('cad', 'Alerts', 'any');
  const userColumns = permissions.ViewDeleted ? defaultColumns : noDeletedColumns;
  const { filter, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(userColumns);
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [viewDeleted, setViewDeleted] = useState(false);
  useEffect(() => {
    search();
  }, [filter, viewDeleted]);

  useEffect(() => {
    if (!dataUpdate || dataUpdate.type !== 'Bulletin') return;
    search();
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return { ...row, id: idx + nextStartingIndex, Read: row.ReadDate ? true : false };
    });
  };

  const search = async (offset = 0, mergeRows = false) => {
    setLoaded(false);
    searchBulletins(text, dateFrom, dateTo, filter, viewDeleted, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);

        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewDetails = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 26, data: { ptsAlertID: item.ptsAlertID }, title: 'Bulletin Details' });
  };

  const add = () => props.addBulletin();

  const edit = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.editBulletin(item.ptsAlertID);
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete selected bulletin?')) return;
    try {
      const item = rows.find((row) => row.id === selection);
      if (!item) return;
      await removeAlert(item.ptsAlertID);
      search();
      //props.notify('Bulletin removed', 'success');
    } catch (err) {
      props.handleError(err, 'Error, Unable to remove bulletin');
    }
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        {permissions.Create && (
          <Tooltip title="Create bulletin">
            <Fab onClick={add} size="small" color="secondary">
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
        {selection != null && (
          <>
            <Tooltip title="Edit Bulletin">
              <Fab onClick={edit} size="small" color="secondary">
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete Bulletin">
              <Fab onClick={handleDelete} size="small" color="secondary">
                <DeleteIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="View details">
              <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                <InfoIcon />
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Bulletin History</h5>
      {renderSearch()}
      {permissions.ViewDeleted && (
        <Checkbox2
          label="Show Deleted"
          checked={viewDeleted}
          onChange={(ev, val) => setViewDeleted(val)}
        />
      )}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={renderActions()}
        loaded={loaded}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Bulletin History"
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
        // pagination={true}
        // pageSize={limit}
        // onPageChange={onPageChange}
        // paginationMode="server"
        // rowCount={rowCount}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    addState: state.dialogs.addBulletin,
    bulletinDetails: state.dialogs.bulletinDetails,
    unreadBulletins: state.unreadBulletins,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  addBulletin,
  editBulletin,
  showReport,
  notify,
})(BulletinSearchResults);
