import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { handleError } from 'reducers/ErrorReducer';
import { showAddPerson } from 'reducers/DialogsReducer';
import TextFieldPlus from './TextFieldPlus';
import { searchCaller } from 'reducers/SearchReducer';
import { showWarrants } from 'reducers/DialogsReducer';
import clsx from 'clsx';
import { showEditPerson } from 'reducers/DialogsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      opacity: 0.2,
      transition: 'all 0.1s',
    },
    '&:hover, & .Mui-focused': {
      '& $personInfo': {
        opacity: 1,
      },
      '& $warrants': {
        opacity: 1,
      },
      '& .MuiAutocomplete-clearIndicator': {
        opacity: 1,
      },
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  personInfo: {
    position: 'absolute',
    right: 60,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s',
  },
  warrants: {
    position: 'absolute',
    right: 90,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s',
  },
  iconButtonError: {
    color: theme.palette.error.main,
  },
  iconButton: {
    color: theme.palette.secondary.main,
  },
}));

function CallerLookup(props) {
  const { label = 'Search Caller' } = props;
  const classes = useStyles();
  const [text, setText] = useState('');
  const [searchMatch, setSearchMatch] = useState([]);
  const [callerData, setCallerData] = useState(null);

  const throttleRef = useRef(0);

  useEffect(() => {
    searchPerson();
    // eslint-disable-next-line
  }, [text]);

  const searchPerson = () => {
    if (text.length < 3) {
      setSearchMatch([]);
      return;
    }
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      searchCaller(text)
        .then((result) => {
          // result = result.map((p) => ({ ...p, BirthDate: displayDate(p.BirthDate) }));
          setSearchMatch(result);
        })
        .catch(props.handleError);
    }, 500);
  };

  const onCallerChange = (data) => {
    // if (props.onPersonChange) return props.onPersonChange(data); // ToDo: should be refactored
    if (props.onChange) return props.onChange(data);
  };

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setCallerData(null);
      onCallerChange(null);
      return;
    }
    setCallerData(selection);
    onCallerChange(selection);
  };

  const getOptionSelected = (option, value) => {
    return option.FullName === value.FullName;
  };

  const renderOption = (option) => <span>{option.FullName}</span>;

  return (
    <div className={clsx(classes.wrap, props.className)}>
      <Autocomplete
        disabled={props.disabled}
        fullWidth
        autoHighlight
        options={searchMatch}
        value={callerData}
        getOptionLabel={(option) => option.FullName}
        size="small"
        onChange={onSearchSelect}
        onInputChange={(ev, val) => setText(val)}
        renderInput={(params) => {
          params.label = label;
          params.variant = 'outlined';
          return (
            <>
              <TextFieldPlus
                {...params}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password',
                }}
              />
            </>
          );
        }}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        filterOptions={(option) => option}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    dialogs: state.dialogs,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showAddPerson,
  showWarrants,
  showEditPerson,
})(CallerLookup);
