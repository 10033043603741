import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationsTab from './NotificationsTab';
import VehiclesTab from './VehiclesTab';
import LocationsTab from './LocationsTab';
import NotesTab from './NotesTab';
import DispositionsTab from './DispositionsTab';
import AttachmentsTab from './AttachmentsTab';
import HistoryTab from './HistoryTab';
import RoutingTab from './RoutingTab';
import CaseIDTab from './CaseIDTab';
import ZonesTab from './ZonesTab';
import PartiesTab from './PartiesTab';
import EventUnitsTab from './EventUnitsTab';
import TimesTab from './TimesTab';
import { setTab } from 'reducers/EventSortingReducer';
import BulletinsTab from './BulletinsTab';
import SopTab from './SopTab';

function EventTabDetails(props) {
  const {
    colorPalette,
    dictionary,
    menuOptions,
    event,
    dispositionWarning,
    lastChange,
    fromEditEventWindow = false,
    disableEdit,
  } = props;

  const {
    CallType,
    ptsEventID,
    assignedUnits,
    UnitStatuses,
    notes,
    dispositions,
    attachments,
    EventRouting,
    CaseIds,
    parties,
    zones,
    Status,
    ptsEventLocationID,
  } = props.event;

  const [tab, setTab] = useState('Units');
  const shouldVisibleSop = tab === 'SOPs' && Status === 'Closed';
  //const [assignedUnits, setAssignedUnits] = useState([]);
  useEffect(() => {
    const state = props.eventSort.state[ptsEventID];
    const tab = state ? state.tab : props.eventSort.lastTab || 'Units';
    setTab(tab);
    if (!state) {
      props.setTab(ptsEventID, tab);
    }
    // eslint-disable-next-line
  }, [props.eventSort]);

  const renderEventUnitsTab = () => {
    return (
      <EventUnitsTab
        units={assignedUnits}
        colorPalette={colorPalette}
        UnitStatuses={UnitStatuses}
        ptsEventID={ptsEventID}
        sortOption={menuOptions.Units.sort}
        fromEditEventWindow={fromEditEventWindow}
        disableEdit={disableEdit}
      />
    );
  };

  const renderNotes = () => {
    return (
      <NotesTab
        notes={notes}
        ptsEventID={ptsEventID}
        sortOption={menuOptions.Notes.sort}
        setFocus={lastChange == 'Notes'}
        disableEdit={disableEdit}
        eventRouting={EventRouting}
      />
    );
  };

  const renderDispositions = () => {
    return (
      <DispositionsTab
        dispositions={dispositions}
        units={assignedUnits}
        EventRouting={EventRouting}
        ptsEventID={ptsEventID}
        sortOption={menuOptions.Dispositions.sort}
        dispositionWarning={dispositionWarning}
        disableEdit={disableEdit}
      />
    );
  };

  return (
    <div>
      {tab === 'Units' && renderEventUnitsTab()}
      {tab === 'Notes' && renderNotes()}
      {tab === 'Dispositions' && renderDispositions()}
      {tab === 'Parties' && (
        <PartiesTab
          data={parties}
          ptsEventID={ptsEventID}
          sortOption={menuOptions.Parties.sort}
          disableEdit={disableEdit}
        />
      )}

      {tab === 'Attachments' && (
        <AttachmentsTab
          attachments={attachments}
          ptsEventID={ptsEventID}
          sortOption={menuOptions.Attachments.sort}
          disableEdit={disableEdit}
        />
      )}
      {tab === 'History' && (
        <HistoryTab
          ptsEventID={ptsEventID}
          colorPalette={colorPalette}
          sortOption={menuOptions.History.sort}
        />
      )}
      {tab === 'Case IDs' && (
        <CaseIDTab
          cases={CaseIds}
          ptsEventID={ptsEventID}
          sortOption={menuOptions['Case IDs'].sort}
          disableEdit={disableEdit}
          eventRouting={EventRouting}
        />
      )}
      {tab === 'Routing' && (
        <RoutingTab
          data={EventRouting}
          dictionary={dictionary}
          ptsEventID={ptsEventID}
          CallType={CallType}
          sortOption={menuOptions.Routing.sort}
          disableEdit={disableEdit}
          eventRouting={EventRouting}
        />
      )}
      {tab === 'Zones' && (
        <ZonesTab
          zones={zones}
          ptsEventLocationID={ptsEventLocationID}
          sortOption={menuOptions.Zones.sort}
          ptsEventID={ptsEventID}
          disableEdit={disableEdit}
        />
      )}
      {tab === 'Notifications' && <NotificationsTab event={event} disableEdit={disableEdit} />}
      {tab === 'Locations' && (
        <LocationsTab
          cases={CaseIds}
          ptsEventID={ptsEventID}
          sortOption={menuOptions.Locations.sort}
          disableEdit={disableEdit}
        />
      )}
      {tab === 'Vehicles' && (
        <VehiclesTab
          units={assignedUnits}
          sortOption={menuOptions.Vehicles.sort}
          ptsEventID={ptsEventID}
          VehicleCount={event.VehicleCount}
          disableEdit={disableEdit}
        />
      )}
      {tab === 'Times' && <TimesTab event={event} />}
      {tab === 'Bulletins' && <BulletinsTab ptsEventID={ptsEventID} disableEdit={disableEdit} />}
      {shouldVisibleSop && (
        <SopTab ptsEventID={ptsEventID} Sops={props.event.sops} disableEdit={disableEdit} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    theme: state.theme,
    colorPalette: state.config.colorPalette,
    eventSort: state.eventSort,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  setTab,
})(EventTabDetails);
