import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from 'components/Dialog';
import { Button } from '@material-ui/core';
import {
  updateUnitStatusPromise,
  getUnitStatus,
  setNotify,
  getInserviceStatus,
  getUnitRecentLocation,
} from 'reducers/UnitStatusReducer';
import EventFilter from 'components/EventFilter';
import ModifiersFilter from './ModifiersFilter';
import { closeNewUnitStatus } from 'reducers/DialogsReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { MdLocationSearching } from 'react-icons/md';
import { geocodeCoords, getCoords } from 'utils/mapFunctions';
import { showBulletinDetails } from 'reducers/DialogsReducer';
import { bulletinFound } from 'reducers/BulletinReducer';
import { findBulletins } from 'reducers/BulletinReducer';
import { authorizeExternalApp, setSearchCookie } from 'reducers/UserReducer';
import Tooltip from 'components/Tooltip';
import { dateTimePicker, getDateTimeDisplay, getCurrentDate } from 'reducers/TimeReducer';
import Dictionary from 'components/Dictionary';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import formStyles, { gridStyle, Row } from 'utils/formStyles';
import DatePicker2 from 'components/DatePicker2';
import TimePicker2 from 'components/TimePicker2';
import TextField2 from 'components/TextField2';
import { formatSaveData } from 'utils/formStyles';
import PlaceLookup from 'components/PlaceLookup';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getUnitAction, saveUnitAction } from 'reducers/SearchReducer';

function getNcicUrl() {
  let url = process.env.REACT_APP_NCIC_URL;
  if (!url) return false;
  if (url.substr(-1) !== '/') url += '/';
  url += 'Search';
  return url;
}

const ncicUrl = getNcicUrl();

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  item: gridStyle(250, '100%'),
  notes: {
    ...gridStyle(250, '100%'),
    '& .MuiOutlinedInput-root': {
      fontSize: 14,
    },
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: `0 ${theme.spacing(2)}px`,
    '& button': {
      marginRight: theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

function getDefaultEvent(events, ptsUnitID) {
  let output = '';
  events.forEach((event) => {
    const result = event.assignedUnits.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (result) output = event.ptsEventID;
  });
  return output;
}

const emptyFormData = {
  UnitStatus: null,
  Location: null,
  Mileage: null,
  Notes: null,
  OLN: null,
  OLNState: null,
  Plate: null,
  PlateState: null,
  Modifiers: [],
  ptsActionID: null,
  ptsDestinationID: null,
  Occurred: null,
  ptsEventID: null,
};

function DialogUnitStatus(props) {
  const classes = useStyles();
  const { events, units, data, permissions, notify, notification, options } = props;
  const { edit, ptsActionID } = data;
  const [unit, setUnit] = useState(null);
  const [ptsEventID, setPtsEventID] = useState(null);
  const [ptsUnitID, setPtsUnitID] = useState(null);
  const [unitLocationCoords, setUnitLocationCoordsData] = useState(null);
  const [formData, setFormData] = useState({ ...emptyFormData });
  const [title, setTitle] = useState('');
  const [stringCoordinates, setStringCoordinates] = useState('');
  const formDataCopy = useRef({ ...emptyFormData });
  const disableNcic = !ncicUrl || !permissions.globals['Execute NCIC Queries'];
  const addDateToNote = options.UnitStatusNotesDate;
  const unitStatusRef = useRef(null);
  const mountedRef = useRef(true);

  useEffect(() => {
    if (ptsActionID) {
      getHistoricUnitAction();
    } else {
      const { ptsUnitID, ptsEventID } = data;
      getEditStatus(ptsUnitID, ptsEventID);
      setPtsEventID(ptsEventID);
      setPtsUnitID(ptsUnitID);
    }
    if (!edit) onChange('Occurred')(null, getCurrentDate());
    setTimeout(() => {
      if (mountedRef.current && unitStatusRef.current) {
        unitStatusRef.current.focus();
      }
    }, 100);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unit = units.find((unit) => unit.ptsUnitID === ptsUnitID);
    if (unit) setUnit(unit);
    // eslint-disable-next-line
  }, [units]);

  const clearForm = () => {
    const newFormData = { ...emptyFormData };
    delete newFormData.Occurred;
    formDataCopy.current = { ...formData, ...newFormData };
    setFormData(formDataCopy.current);
  };

  const getHistoricUnitAction = async () => {
    try {
      const result = await getUnitAction(ptsActionID);
      if (!result || !mountedRef.current) return;
      processData(result);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getEditStatus = async (ptsUnitID, ptsEventID) => {
    try {
      const result = await getUnitStatus(ptsUnitID, ptsEventID);
      processData(result);
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (result) => {
    const InServiceStatus = getInserviceStatus();

    const {
      Location,
      Mileage,
      Notes,
      OLN,
      OLNState,
      Plate,
      PlateState,
      ptsActionID,
      ptsDestinationID,
      EventID,
      Action,
    } = result;

    const Occurred = dateTimePicker(result.Occurred);
    const formDataFromResult = {
      Location,
      Mileage,
      Notes,
      OLN,
      OLNState,
      Plate,
      PlateState,
      ptsActionID,
      ptsDestinationID,
      Occurred,
      EventID,
      status: Action,
    };
    formDataFromResult.ptsEventID = data.ptsEventID
      ? data.ptsEventID
      : getDefaultEvent(events, data.ptsUnitID);
    if (edit) {
      formDataFromResult.UnitStatus = result.Action;

      formDataCopy.current = { ...emptyFormData, ...formDataFromResult };
      setFormData(formDataCopy.current);
    } else if (formData.UnitStatus !== InServiceStatus) {
      delete formDataFromResult.ptsActionID;
      formDataFromResult.Occurred = getCurrentDate();
      formDataCopy.current = { ...formDataFromResult };
      setFormData((prevState) => ({ ...prevState, ...formDataCopy.current }));
    }
    // set title
    let title = data.title;
    if (ptsActionID && result.Unit) {
      title += ` - ${result.Unit}`;
    } else {
      title += unit && unit.Unit ? ' - ' + unit.Unit : ' ';
    }
    setTitle(title || ' ');
  };

  const handleClose = () => {
    props.closeNewUnitStatus();
  };

  useEffect(() => {
    if (notify && notification) {
      props.bulletinFound(notification);
      props.setNotify(false);
    }
  }, [notify, notification]);

  useEffect(() => {
    (async () => {
      const location = await getUnitRecentLocation(ptsUnitID);
      if (location && location.length) {
        const coords = {
          lat: location[0].LatitudeDegree,
          lng: location[0].LongitudeDegree,
        };
        const stringCoordinates = getCoordinatesInString(location[0]);
        setStringCoordinates(stringCoordinates);
        setUnitLocationCoordsData(coords);
      }
    })();
  }, [ptsUnitID]);

  function getCoordinatesInString(data) {
    const coords = getCoords(data);
    return `${coords.lat},${coords.lng}`;
  }
  const save = () => {
    const data = formatSaveData(formData);
    data.ptsUnitID = ptsUnitID;
    if (ptsActionID) {
      // update historic data
      updateUnitAction(data);
    } else {
      // add/create unit status
      createUpdateUnitStatus(data);
    }
  };

  const createUpdateUnitStatus = (data) => {
    props.showSpinner();
    updateUnitStatusPromise(data, formData.ptsEventID, formData.ptsActionID)
      .then(() => props.findBulletins(data))
      .then(() => {
        props.closeNewUnitStatus();
        props.notifyDataUpdate({ type: 'unit-status' });
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const updateUnitAction = (data) => {
    props.showSpinner();
    saveUnitAction(data)
      .then((result) => {
        props.notifyDataUpdate({ type: 'unit-status' });
      })
      .catch(props.handleError)
      .finally(props.hideSpinner);
  };

  const handleOlnSearch = () => {
    const { OLNState, OLN } = formData;
    authorizeExternalApp();
    const cookieData = {
      type: 'oln',
      oln: OLN,
      state: OLNState ? OLNState.Code : null,
      ptsUnitID,
    };
    setSearchCookie(cookieData);
  };

  const handlePlateSearch = () => {
    const { Plate, PlateState } = formData;
    authorizeExternalApp();
    const cookieData = {
      type: 'plate',
      plate: Plate,
      state: PlateState ? PlateState.Code : null,
      ptsUnitID,
    };
    setSearchCookie(cookieData);
  };

  const renderActions = () => {
    const { Plate, OLN } = formData;
    const olnDisabled = !OLN || !ncicUrl || disableNcic;
    const plateDisabled = !Plate || !ncicUrl || disableNcic;
    return (
      <div className={classes.actions}>
        <div>
          <Tooltip title="NCIC search">
            <span>
              <Button
                component="a"
                onClick={authorizeExternalApp}
                href={ncicUrl.toString()}
                target="_ncic"
                disabled={disableNcic}>
                Search
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="NCIC OLN search">
            <span>
              <Button
                component="a"
                onClick={handleOlnSearch}
                href={ncicUrl.toString()}
                target="_ncic"
                disabled={olnDisabled}>
                OLN
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="NCIC plate earch">
            <span>
              <Button
                component="a"
                onClick={handlePlateSearch}
                href={ncicUrl.toString()}
                target="_ncic"
                disabled={plateDisabled}>
                Plate
              </Button>
            </span>
          </Tooltip>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            onClick={clearForm}>
            <CloseIcon /> Clear
          </Button>
          <Button variant="contained" color="primary" type="submit" size="small" onClick={save}>
            <SaveIcon /> Save
          </Button>
          <Button onClick={handleClose} color="primary" size="small">
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  const onChange = (name) => (ev, val) => {
    formDataCopy.current = { ...formDataCopy.current, [name]: val };
    setFormData((prevState) => ({ ...prevState, ...formDataCopy.current }));
  };
  const setCurrentTime = () => {
    if (!confirm('Enter current time?')) return;
    onChange('Occurred')(null, getCurrentDate());
  };

  const renderStatus = () => {
    return (
      <Dictionary
        options="UnitActions"
        className={classes.item}
        onChange={onChange('UnitStatus')}
        value={formData.UnitStatus}
        label="Status"
        inputRef={unitStatusRef}
        compact="true"
      />
    );
  };

  const renderStatusDateTime = () => {
    return (
      <>
        <DatePicker2
          label="Edit Status Date"
          className={classes.item}
          value={formData.Occurred}
          onChange={onChange('Occurred')}
        />
        <TimePicker2
          className={classes.item}
          label="Edit Status Time"
          value={formData.Occurred}
          onChange={onChange('Occurred')}
        />
        <Tooltip title="Set current date/time">
          <span>
            {' '}
            <Fab onClick={setCurrentTime} size="small" color="secondary">
              <AccessTimeIcon style={{ fontSize: '1.3rem' }} />
            </Fab>
          </span>
        </Tooltip>
      </>
    );
  };

  const renderEvent = () => {
    return (
      <EventFilter
        className={classes.item}
        ptsEventID={formData.ptsEventID}
        onChange={onChange('ptsEventID')}
      />
    );
  };

  const renderPastEvent = () => {
    return (
      <TextField2
        label="Event"
        value={formData.EventID || ''}
        className={classes.item}
        compact="true"
        disabled
      />
    );
  };

  const findUnitLocation = async () => {
    try {
      const STREET_ADDRESS = 'street_address';
      const addresses = await geocodeCoords(unitLocationCoords);
      let Location = '';
      const address = addresses.find((address) => address.type === STREET_ADDRESS);
      if (address) Location = address.formattedAddress.substr(0, 100);
      else Location = stringCoordinates;
      onChange('Location')(null, Location);
    } catch (err) {
      console.log('', err);
    }
  };

  const renderLocation = () => {
    return (
      <>
        <TextField2
          label="Location"
          className={classes.item}
          onChange={onChange('Location')}
          value={formData.Location}
          max={100}
          compact="true"
        />
        <Tooltip title="Find current location">
          <span>
            {' '}
            <Fab
              onClick={findUnitLocation}
              size="small"
              color="secondary"
              disabled={!unitLocationCoords || !Object.keys(unitLocationCoords).length}>
              <MdLocationSearching style={{ fontSize: '1.3rem' }} />
            </Fab>
          </span>
        </Tooltip>
      </>
    );
  };

  const renderPlate = () => {
    return (
      <TextField2
        label="Plate"
        className={classes.item}
        onChange={onChange('Plate')}
        value={formData.Plate}
        max={25}
        compact="true"
      />
    );
  };

  const renderPlateState = () => {
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange('PlateState')}
        value={formData.PlateState}
        label="Plate State"
        compact="true"
      />
    );
  };

  const renderOln = () => {
    return (
      <TextField2
        label="OLN"
        className={classes.item}
        onChange={onChange('OLN')}
        value={formData.OLN}
        max={50}
        compact="true"
      />
    );
  };

  const renderOlnState = () => {
    return (
      <Dictionary
        options="States"
        className={classes.item}
        onChange={onChange('OLNState')}
        value={formData.OLNState}
        label="OLN State"
        compact="true"
      />
    );
  };

  const renderMileage = () => {
    return (
      <TextField2
        label="Mileage"
        className={classes.item}
        onChange={onChange('Mileage')}
        value={formData.Mileage}
        min={0}
        max={99999999999}
        type="number"
        compact="true"
      />
    );
  };

  const renderNotes = () => {
    const getUserData = () => {
      if (!addDateToNote) return '';
      const userName = props.user?.userData?.user?.Username;
      const created = getDateTimeDisplay() + (userName ? `, ${userName}` : '') + ': ';
      return created;
    };
    const onClick = () => {
      if (!formData.Notes) {
        const userData = getUserData();
        userData && onChange('Notes')(null, userData);
      }
    };
    const handleChange = (ev, val) => {
      const ch = val.charCodeAt(val.length - 1);
      if (ch === 10) return onChange('Notes')(ev, val + getUserData());
      onChange('Notes')(ev, val);
    };
    return (
      <TextField2
        label="Notes"
        className={classes.notes}
        onChange={handleChange}
        value={formData.Notes}
        onClick={onClick}
        multiline
        rows={3}
        compact="true"
      />
    );
  };
  const renderDestination = () => {
    const onPlaceChange = (place) => {
      const ptsPlaceID = place?.ptsPlaceID || null;
      onChange('ptsDestinationID')(null, ptsPlaceID);
    };
    return (
      <PlaceLookup
        onChange={onPlaceChange}
        className={classes.item}
        label="Add destination"
        ptsPlaceID={formData.ptsDestinationID}
      />
    );
  };

  const renderModifiers = () => {
    return (
      <ModifiersFilter
        className={classes.item}
        selectedModifiers={formData.Modifiers}
        onChange={onChange('Modifiers')}
        disabled={Boolean(ptsActionID)}
      />
    );
  };

  return (
    <Dialog onClose={handleClose} title={title} actions={renderActions()}>
      <Row>{renderStatusDateTime()}</Row>
      <Row>{Boolean(ptsActionID) ? renderPastEvent() : renderEvent()}</Row>
      <Row>{renderStatus()}</Row>
      <Row>{renderLocation()}</Row>
      <Row>
        {renderPlate()}
        {renderPlateState()}
      </Row>
      <Row>
        {renderOln()}
        {renderOlnState()}
      </Row>
      <Row>{renderMileage()}</Row>
      <Row>{renderNotes()}</Row>
      <Row>{renderDestination()}</Row>
      <Row>{renderModifiers()}</Row>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  events: state.events,
  units: state.units,
  user: state.user,
  notify: state.unitStatus.notify,
  notification: state.unitStatus.notification,
  permissions: state.permissions,
  options: state.config.options,
});

export default connect(mapStateToProps, {
  closeNewUnitStatus,
  showSpinner,
  hideSpinner,
  handleError,
  setNotify,
  showBulletinDetails,
  bulletinFound,
  findBulletins,
  notifyDataUpdate,
})(DialogUnitStatus);
