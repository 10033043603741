////// COMPONENTS //////
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import XGrid3 from 'components/XGrid3';
import Actions from './Actions';
import Checkbox2 from 'components/Checkbox2';
import TextField2 from 'components/TextField2';
import EventFilters from './Filters';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
////// REDUCERS //////
import { handleError } from 'reducers/ErrorReducer';
import { searchEvents } from 'reducers/SearchReducer';
import { getTimeFromDate } from 'reducers/TimeReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
////// REDUCERS //////
import {
  advancedEventDefaultColumns,
  advancedEventsNoDeletedColumns,
  eventDefaultColumns,
  eventNoDeletedColumns,
} from 'utils/gridColumns';

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  tabIndicator: {
    backgroundColor: 'white',
  },
  fieldsetWrapperFilter: {
    border: '1px solid ',
    borderColor: theme.colors.border,
    borderRadius: '10px',
    padding: '15px 15px ',
    width: '100%',
  },
  fieldsetTitleFilter: {
    position: 'absolute',
    marginTop: '-25px',
    color: theme.colors.text1,
    background: theme.colors.border,
    borderRadius: '10px',
    padding: '2px 10px',
  },
  search: {
    width: 450,
    marginRight: 8,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    marginTop: '10px',
  },
  actionGrid: { display: 'flex', justifyContent: 'flex-end' },
}));

const filtersObj = {
  dateFrom: new Date(),
  dateTo: new Date(),
  timeFrom: null,
  timeTo: null,
  unit: [],
  zone: [],
  disposition: [],
  partyName: null,
  partyType: null,
  partyRelationship: {},
};

function EventHistory(props) {
  const LIMIT = 1000;
  const BASIC = 'basic';
  const ADVANCED = 'advanced';
  const { filter } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const getColumns = (primary, secondary) => (permissions.ViewDeleted ? primary : secondary);
  const basicEvColumns = getColumns(eventDefaultColumns, eventNoDeletedColumns);
  const advancedEvColumns = getColumns(advancedEventDefaultColumns, advancedEventsNoDeletedColumns);
  const [tab, setTab] = useState(BASIC);
  const [text, setText] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const [filters, setFilters] = useState(filtersObj);
  const [rowCount, setRowCount] = useState(0);
  const [selection, setSelection] = useState(null);
  const [printFlag, setPrintFlag] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [ViewDeleted, setViewDeleted] = useState(false);
  const [basicColumns, setBasicColumns] = useState(basicEvColumns);
  const [advancedColumns, setAdvancedColumns] = useState(advancedEvColumns);
  const [selectionStatus, setSelectionStatus] = useState(null);
  const dataUpdate = useSelector((state) => state.dataUpdate);

  ////////// USE EFFECTS //////////
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [tab, filter]);

  useEffect(() => {
    if (dataUpdate?.type === 'event') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [ViewDeleted]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= LIMIT) setShowAlert(true);
  }, [rows, rowCount]);

  ////////// ACTIONS //////////
  const search = (offset = 0, mergeRows = false) => {
    setLoaded(false);
    const {
      dateFrom,
      dateTo,
      startTime,
      endTime,
      filter,
      unit,
      disposition,
      partyName,
      partyType,
      zone,
      partyRelationship,
    } = validateFilterData();
    searchEvents(
      text,
      dateFrom,
      dateTo,
      startTime,
      endTime,
      filter,
      unit,
      disposition,
      partyName,
      partyType,
      zone,
      partyRelationship,
      ViewDeleted,
      offset,
      LIMIT,
      tab
    )
      .then((result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);
        const nextStartingIndex = rows.length;

        //TRUE means, fetching next rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...getProcessData(result, nextStartingIndex)]);
        else {
          //new search result
          setRows(getProcessData(result));
          //resetting page
          setPage(0);
        }
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => setLoaded(true));
  };

  //////////////////// HANDLER //////////////////
  const onPageChange = () => {
    const offset = (page + 1) * LIMIT;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const handleTabChange = (ev, newValue) => setTab(newValue);

  const handleFilterChange = (key, value) =>
    setFilters((prevState) => ({ ...prevState, [key]: value }));

  const onSelection = (selection) => {
    setSelection(selection);
    const item = rows.find((row) => row.id === selection);
    const Status = item ? item.Status.toLowerCase() : null;
    setSelectionStatus(Status);
  };

  ////////////////// HELPER ///////////////////

  const getProcessData = (data, nextStartingIndex = 0) => {
    return tab === BASIC ? basicDataProcess(data) : advancedDataProcess(data, nextStartingIndex);
  };

  const basicDataProcess = (data) => {
    return data.map((row, idx) => {
      const { AgencyIDs, CaseIDs, ptsEventID } = row;
      let AgencyID, CaseID;
      if (AgencyIDs)
        AgencyID = JSON.parse(AgencyIDs)
          .map((it) => it.AgencyID)
          .join(', ');
      if (CaseIDs)
        CaseID = JSON.parse(CaseIDs)
          .map((it) => it.CaseID)
          .join(', ');

      return { ...row, id: ptsEventID, AgencyID, CaseID };
    });
  };

  const advancedDataProcess = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return { ...row, id: idx + nextStartingIndex };
    });
  };

  const validateFilterData = () => {
    const {
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      unit,
      zone,
      disposition,
      partyName,
      partyType,
      partyRelationship,
    } = filters;
    const startTime = timeFrom ? getTimeFromDate(timeFrom) : timeFrom;
    const endTime = timeFrom ? getTimeFromDate(timeTo) : timeTo;
    return {
      dateFrom,
      dateTo,
      startTime,
      endTime,
      filter,
      unit: unit && unit.length ? getCodeArray(unit, 'Code') : null,
      disposition: disposition && disposition.length ? getCodeArray(disposition, 'Code') : null,
      partyName,
      partyType,
      zone: zone && zone.length ? getCodeArray(zone, 'ZoneCode') : null,
      partyRelationship:
        partyRelationship && Object.keys(partyRelationship).length ? partyRelationship.Code : null,
    };
  };

  function getCodeArray(array, type) {
    return array.map((item) => item[type]);
  }

  ////////////////// RENDER //////////////////////

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      // <Grid container xs={12}>
      // <Grid item xs={12}>
      <>
        <div className={classes.filterWrap}>
          <div className={classes.filters}>
            <TextField2
              value={text}
              onChange={onChange}
              type="search"
              className={classes.search}
              onKeyDown={onKeyDown}
              compact="true"
            />
            <Button variant="outlined" onClick={() => search()} size="large">
              Search
            </Button>

            <Checkbox2
              label="Show Deleted"
              checked={ViewDeleted}
              onChange={(ev, val) => setViewDeleted(val)}
              className={classes.checkbox}
            />
          </div>
        </div>
      </>
    );
  };

  const renderBasicGrid = () => {
    return (
      <>
        {showAlert && (
          <RecordLimitAlert
            handleNextPage={onPageChange}
            rowCount={rowCount}
            currentRows={rows.length}
          />
        )}
        <XGrid3
          name={'Events'}
          columns={basicColumns}
          setColumns={setBasicColumns}
          rows={rows}
          loading={!loaded}
          setSelection={onSelection}
          printFlag={printFlag}
          refresh={search}
        />
      </>
    );
  };

  const renderAdvancedGrid = () => {
    return (
      <>
        {showAlert && (
          <RecordLimitAlert
            handleNextPage={onPageChange}
            rowCount={rowCount}
            currentRows={rows.length}
          />
        )}
        <XGrid3
          name={'Advanced Events'}
          columns={advancedColumns}
          setColumns={setAdvancedColumns}
          rows={rows}
          loading={!loaded}
          setSelection={onSelection}
          printFlag={printFlag}
          refresh={search}
        />
      </>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs
        //TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
        value={tab}
        onChange={handleTabChange}
        className={classes.tabs}>
        <Tab label="Basic" value={BASIC} />
        <Tab label="Advanced" value={ADVANCED} />
      </Tabs>
    );
  };

  if (!permissions.Read) return <Redirect to="/" />;
  return (
    <>
      <Grid container xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={8}>
            <div className={classes.fieldsetWrapperFilter}>
              <div className={classes.fieldsetTitleFilter}>Filters</div>

              <EventFilters
                loaded={loaded}
                search={search}
                filters={filters}
                onChange={handleFilterChange}
                agency={filter}
              />
              <div />
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: '10px' }}>
          <Grid item xs={7}>
            {renderSearch()}
          </Grid>
          <Grid item xs={5} className={classes.actionGrid}>
            <Actions
              rows={rows}
              selection={selection}
              selectionStatus={selectionStatus}
              setPrintFlag={setPrintFlag}
              type={tab}
            />
          </Grid>
        </Grid>
      </Grid>

      {renderTabs()}
      {tab === 'basic' && renderBasicGrid()}
      {tab === 'advanced' && renderAdvancedGrid()}
    </>
  );
}

export default EventHistory;
