import moment from 'moment';
import { getService } from './service';
import { formatSaveDateFrom, formatSaveDateTo } from './TimeReducer';
import { addCreatedUpdatedBy } from 'utils/functions';
import store from '../config/configureStore';

export const formatDateFrom = (date) => {
  if (!date) return null;
  const state = store.store.getState();
  const { timeOffset } = state.time;
  const d = moment(date).format('YYYY-MM-DD') + ' 00:00:00.000';
  const m = moment(d).subtract(timeOffset, 'minutes');
  if (!m.isValid()) return null;
  const result = m.format('YYYY-MM-DD HH:mm:ss');
  return result;
};
export const searchNotifications = async (search = '', dateFrom, dateTo, filter, offset, limit) => {
  const service = getService('cad-search-notifications');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      filter,
      offset,
      limit,
    },
  });
};

export const searchPlaceAddresses = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  const result = await service.find({
    query: { ptsPlaceID, IsDeleted: 0 },
  });

  if (!result?.data?.length) return [];
  return result.data.map((r) => r.Address);
};

export const searchUnitStatus = (
  search = '',
  dateFrom,
  dateTo,
  startTime,
  endTime,
  filter,
  unit,
  offset,
  limit
) => {
  const service = getService('cad-search-unit-status');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      startTime,
      endTime,
      filter,
      unit,
      offset,
      limit,
    },
  });
};

export const getUnitAction = (ptsActionID) => {
  const service = getService('cad-search-unit-status');
  return service.get(ptsActionID);
};

export const saveUnitAction = (data) => {
  const service = getService('cad-search-unit-status');
  return service.patch(data.ptsActionID, data);
};

export const searchAdvancedEvents = (
  search = '',
  dateFrom,
  dateTo,
  startTime,
  endTime,
  filter,
  ViewDeleted = false,
  offset,
  limit
) => {
  const service = getService('cad-search-advanced-events');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      startTime,
      endTime,
      filter,
      ViewDeleted,
      offset,
      limit,
    },
  });
};

export const searchEvents = (
  search = '',
  dateFrom,
  dateTo,
  startTime,
  endTime,
  filter,
  unit,
  disposition,
  partyName,
  partyType,
  zone,
  partyRelationship,
  ViewDeleted = false,
  offset,
  limit,
  type
) => {
  const currentService = type === 'basic' ? 'cad-search-events' : 'cad-search-advanced-events';
  const service = getService(currentService);

  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      startTime,
      endTime,
      filter,
      unit,
      disposition,
      partyName,
      partyType,
      zone,
      partyRelationship,
      ViewDeleted,
      offset,
      limit,
    },
  });
};

export const searchNotes = (search = '', dateFrom, dateTo, filter, offset, limit) => {
  const service = getService('cad-search-notes');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      filter,
      offset,
      limit,
    },
  });
};

export const searchUnits = (AgencyID, offset, limit) => {
  const service = getService('cad-search-units');
  return service.find({ query: { AgencyID, offset, limit } });
};

export const addUnit = (data) => {
  const service = getService('cad-search-units');
  return service.create(data);
};

export const editUnit = (ptsUnitID, data) => {
  const service = getService('cad-search-units');
  return service.patch(ptsUnitID, data);
};

export const searchAgencies = (AgencyID) => {
  const service = getService('cad-search-agencies');
  return service.find({ query: { AgencyID } });
};

export const searchEmployees = (search = '', offset, limit) => {
  const service = getService('cad-search-employees');
  return service.find({ query: { search, offset, limit } });
};

export const searchNames = (search = '', offset, limit) => {
  const service = getService('cad-search-names');
  return service.find({ query: { search, offset, limit } });
};

export const getName = (ptsNameID) => {
  const service = getService('cad-search-names');
  return service.get(ptsNameID);
};

export const updateName = (ptsNameID, data) => {
  const service = getService('cad-search-names');
  return service.patch(ptsNameID, data);
};

export const searchCredentials = (search = '', offset, limit) => {
  const service = getService('cad-search-credentials');
  return service.find({ query: { search, offset, limit } });
};

export const searchImages = (pageNumber) => {
  const service = getService('cad-search-images');
  return service.find({ query: { pageNumber } });
};

export const searchSops = (offset, limit) => {
  const service = getService('cad-search-sops');
  return service.find({ query: { offset, limit } });
};

export const searchPhoneNumbers = (search, dateFrom, dateTo, startTime, endTime, offset, limit) => {
  const service = getService('cad-search-phone-numbers');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      startTime,
      endTime,
      offset,
      limit,
    },
  });
};

export const searchPeople2 = (search = '', ViewDeleted = false, offset, limit) => {
  const service = getService('cad-search-people2');
  return service.find({ query: { search, ViewDeleted, offset, limit } });
};

export const searchCaller = (search = '') => {
  const service = getService('cad-search-caller');
  return service.find({ query: { search } });
};

export const searchAddresses = (
  search = '',
  ViewDeleted = false,
  showInEventLookup = false,
  offset,
  limit
) => {
  const service = getService('cad-search-addresses');
  return service.find({ query: { search, ViewDeleted, showInEventLookup, offset, limit } });
};

export const searchAddress = (ptsAddressID) => {
  const service = getService('cad-search-addresses');
  return service.get(ptsAddressID);
};

export const searchAdvancedAddresses = (
  search = '',
  ViewDeleted = false,
  showInEventLookup = false,
  offset,
  limit
) => {
  const service = getService('cad-search-advanced-addresses');
  return service.find({ query: { search, ViewDeleted, showInEventLookup, offset, limit } });
};

export const searchItems = (offset, limit) => {
  const service = getService('cad-search-items');
  return service.find({ query: { offset, limit } });
};

export const searchPlaces = (search = '', ViewDeleted = false, offset, limit) => {
  const service = getService('cad-search-places');
  return service.find({ query: { search, ViewDeleted, offset, limit } });
};

export const searchVehicles = (search = '', ViewDeleted = false, offset, limit) => {
  const service = getService('cad-search-vehicles');
  return service.find({ query: { search, ViewDeleted, offset, limit } });
};

export const searchSpecificVehicle = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle');
  return service.get(ptsVehicleID);
};

export const editVehicle = (ptsVehicleID, data) => {
  const service = getService('cad-pts-vehicle');
  return service.patch(ptsVehicleID, data);
};

export const addVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.create(data);
};

export const removeVehicle = (data) => {
  const service = getService('cad-search-vehicles');
  return service.remove(data);
};

export const searchVehicleRegistrations = (ptsVehicleID) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.find({
    query: { ptsVehicleID },
  });
};

export const addVehicleReg = (data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.create(data);
};

export const updateVehicleReg = (id, data) => {
  const service = getService('cad-pts-vehicle-reg');
  return service.patch(id, data);
};

export const searchVehicleTowings = (search = '', dateFrom, dateTo, offset, limit) => {
  const service = getService('cad-search-vehicle-towings');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      offset,
      limit,
    },
  });
};
//changed here
export const searchZones = (AgencyID, offset, limit) => {
  const service = getService('cad-search-zones');
  return service.find({ query: { AgencyID, offset, limit } });
};

export const getPtsPerson = (ptsPersonID) => {
  const service = getService('cad-search-people');
  return service.get(ptsPersonID);
};

export const getPtsPlace = (ptsPlaceID) => {
  const service = getService('places');
  return service.get(ptsPlaceID);
};

export const updatePtsPlace = (ptsPlaceID, data) => {
  const service = getService('cad-search-places');
  return service.patch(ptsPlaceID, data);
};

export const addPlace = (data) => {
  const service = getService('cad-search-places');
  return service.create(data);
};

export const searchRotations = (search = '', dateFrom, dateTo, filter, offset, limit) => {
  const service = getService('cad-search-rotation-history');
  return service.find({
    query: {
      search,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      filter,
      offset,
      limit,
    },
  });
};

export const searchQueries = (dateFrom, dateTo, filter, offset, limit) => {
  const service = getService('cad-search-ncic-queries');
  return service.find({
    query: {
      dateFrom: moment(dateFrom).format('YYYY-MM-DD') + ' 00:00:00.000',
      dateTo: moment(dateTo).format('YYYY-MM-DD') + ' 00:00:00.000',
      filter,
      offset,
      limit,
    },
  });
};

export const removeUnitStatus = (ptsActionID) => {
  const service = getService('cad-search-unit-status');
  return service.remove(ptsActionID);
};

export const editCredential = (ptsCredentialID, data) => {
  const service = getService('cad-search-credentials');
  return service.patch(ptsCredentialID, data);
};

export const addOrEditPerson = (ptsPersonID, data) => {
  const service = getService('cad-search-people');
  return ptsPersonID && ptsPersonID !== true
    ? service.patch(ptsPersonID, data)
    : service.create(data);
};

export const getContactMethods = async (id, PartyType = 'Person') => {
  const service = getService('record-person-contact');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: id,
    PartyType: PartyType,
    Info: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });

  return result;
};
export const getPeopleCredentials = async (personId) => {
  const service = getService('record-person-credentials');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
    Number: {
      $ne: '',
    },
  };

  const result = await service.find({
    query: q,
  });

  return result;
};
export const getPeopleEmployments = async (personId) => {
  const service = getService('record-person-employment');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const getPeopleAssociate = async (ptsPersonID) => {
  const service = getService('cad-person-associate');
  const result = await service.find({ query: { ptsPersonID } });

  return result;
};

export const createPeopleAssociate = async (data) => {
  const saveData = addCreatedUpdatedBy(data);
  const service = getService('record-person-associates');
  await service.create(saveData);
};

export const getPeopleFeatures = async (personId) => {
  const service = getService('record-person-feature');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const getPeopleNames = async (personId) => {
  const service = getService('record-person-name');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPersonID: personId,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const createPersonName = async (data) => {
  const service = getService('record-person-name');
  const saveData = addCreatedUpdatedBy(data);
  return service.create(saveData);
};

export const getPeopleVehicle = async (personId) => {
  const service = getService('record-person-vehicle-registration');
  let query = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
  };

  const result = await service.find({ query });
  return result;
};

export const getPeopleAddresses = async (ptsPersonID) => {
  const service = getService('record-person-address');
  const query = {
    ptsPersonID,
  };
  return service.find({ query });
};
// export const getPlaceAddresses = async (ptsPlaceID) => {
//   const service = getService('place-addresses');
//   const query = {
//     ptsPlaceID,
//   };
//   return service.find({ query });
// };

export const savePersonAddress = async (data) => {
  const service = getService('record-person-address');
  return service.create(data);
};

export const getplaceAddress = async (ptsPlaceID) => {
  const service = getService('cad-search-place-address');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsPlaceID: ptsPlaceID,
  };

  const result = await service.find({
    query: q,
  });
  return result;
};

export const getPeopleSops = async (personId, ParentType = 'Person') => {
  const service = getService('record-person-sops');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: personId,
    ParentType: ParentType,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const getZoneSops = async (IntCode, ParentType = 'Zone') => {
  const service = getService('record-person-sops');
  let q = {
    IsDeleted: false,
    $sort: {
      Created: -1,
    },
    ptsParentID: IntCode,
    ParentType: ParentType,
  };

  const result = await service.find({
    query: q,
  });

  return result;
};

export const savePlaceAddress = async (data) => {
  const service = getService('cad-place-address');
  return service.create(data);
};

export const addPersonEmployment = (data) => {
  const service = getService('cad-search-people-employment');
  return service.create(data);
};

export const getUnitsResources = async () => {
  const service = getService('settings-resources');
  const result = await service.get({ type: 'units-resources' });
  return result;
};

export const saveUnitPerson = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-person', data });
};

export const removeUnitResource = (ptsResourceID) => {
  const service = getService('settings-resources');
  return service.patch(ptsResourceID, { type: 'remove-unit-resource', data: { ptsResourceID } });
};

export const editUnitResource = (ptsResourceID, data) => {
  const service = getService('settings-resources');
  return service.patch(ptsResourceID, { type: 'edit-unit-resource', data });
};

export const saveUnitVehicle = (data) => {
  const service = getService('settings-resources');
  return service.patch(data.ptsUnitID, { type: 'unit-vehicle', data });
};

export const getPersonDnaSamples = (ptsPersonID) => {
  const service = getService('cad-person-dna-samples');
  return service.get(ptsPersonID);
};

export const getPlaceByID = (ptsPlaceID) => {
  const service = getService('cad-search-places');
  return service.get(ptsPlaceID);
};

export const createDnaSample = (data) => {
  const service = getService('cad-person-dna-samples');
  return service.create(data);
};

export const addPersonFeature = (data) => {
  const service = getService('cad-person-features');
  return service.create(data);
};

export const getPersonImages = (ptsPersonID) => {
  const service = getService('cad-person-photo');
  return service.get(ptsPersonID);
};

export const editPersonImage = (ptsImageID, data) => {
  const service = getService('cad-person-photo');
  return service.patch(ptsImageID, data);
};

export const removePersonImage = (ptsImageID) => {
  const service = getService('cad-person-photo');
  return service.remove(ptsImageID);
};

export const getPersonRecordDetails = (ptsPersonID) => {
  const service = getService('search-person');
  return service.get(ptsPersonID);
};

export const removePlaceAddress = (data) => {
  const service = getService('cad-place-address');
  return service.remove(data);
};

export const getUnitResource = (ptsResourceID) => {
  const service = getService('cad-search-resources');
  return service.get(ptsResourceID);
};

export const getUnit = (ptsUnitID) => {
  const service = getService('cad-search-units');
  return service.get(ptsUnitID);
};
